<template>
  

  <div class="grid">
    <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#274461"
    :is-full-page="fullPage"
  ></loading>
    <div class="col-12">
      <div class="card">
        <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <span class="p-float-label">
                <InputText v-model="department_name"  > </InputText>
                <label>Department Name</label>
             </span>
                </div> &nbsp;

                <div class="my-2">
                  <span class="p-float-label">
                  <Dropdown v-if="user_key && (user_key=='Admin' || user_key=='admin')" id="state" filter  v-model="client_id_search"  style="width:150px" :options="client_id_dropdown" optionLabel="name" :optionValue="value"></Dropdown>
                <label for="client">Select Client</label>
                </span>
                </div>&nbsp;
                
                <div class="my-2">
                <Button
                  icon="pi pi-search"
                  v-tooltip.bottom="'Search'"
                  @click="search(1)"
                /> 
              <!-- <Button
                label="New"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew"
              /> -->
              <!-- <Button
                label="Delete"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="!selectedProducts || !selectedProducts.length"
              /> -->
              <!-- &nbsp;
              <Button
                icon="pi pi-sync"
                class="p-button-info mr-2"
                @click="get_list"
              /> -->
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            /> -->
            <Button
                
                icon="pi pi-plus"
                v-tooltip.bottom="'Add'"
                @click="openNew"
              />&nbsp;
            <json-excel :data="product">
            <Button
              
              icon="pi pi-upload"
              v-tooltip.bottom="'Export'"
             @click="exportCSV"
            />
            </json-excel>
          </template>
        </Toolbar>
      
        <DataTable
          :loading="loading"
          ref="dt"
          :lazy="true"
          :totalRecords="totalRecords"
          :paginator="true"
          :value="products"
          v-model:selection="selectedProducts"
          :dataKey="columns[0]"
          :rows="limit"
          :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, totalRecords]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll"
          style="font-size: 12px"
          :rowHover="true" showGridlines
        >
        <template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b>Departments</b></h5>
              <!-- Total Records:{{totalRecords}} -->
              
              <Button icon="pi pi-refresh"   v-tooltip.bottom="'Refresh'" @click="search(2)" />
              <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span> -->
            </div><div style="text-align:right;font-weight:bold"><span>Total Departments:</span>{{totalRecords}}</div>
          </template>
          
          <!-- <Column v-for="(col,index) in columns" :field="col" :header="col.toUpperCase()" :key="index"></Column> -->
          <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->

          <Column header="Status" style="min-width: 8rem ;text-align: center;" :field="columns[8]" >
            <template #body="{ data }"> 
              <span v-if="data.status=='Active' ||data.status=='active'"><badge :value=data.status severity="success"></badge></span>
            <span v-else-if="data.status=='InActive' ||data.status=='In Active'"><badge :value=data.status severity="warning"></badge></span>
            <span v-else-if="data.status=='Delete' ||data.status=='Deleted'"><badge :value=data.status severity="danger"></badge></span>
            <span v-else>-</span>
            </template>
          </Column>

          <Column header="Sr No" style="min-width: 5rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="page_no==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
            </template>
          </Column>
           
          
          <Column
            header="Name"
            :field="columns[2]"
            style="min-width: 5rem"
            >
            <template #body="{ data }">
              {{ data.name }}
            </template>
          </Column>
          
          
          <Column header="Email" :field="columns[15]" style="min-width: 10rem">
            <template #body="{ data }">
              <span v-if=" data.email_id">{{data.email_id}}</span>
              <span v-else>-</span>
              
            </template>
          </Column>
          <Column header="City" :field="columns[9]" style="min-width: 5rem">
            <template #body="{ data }">
              <span v-if=" data.city">{{data.city}}</span>
              <span v-else>-</span>
            </template>
          </Column>
          <Column header="State" :field="columns[12]">
            <template #body="{ data }">
             <span v-if=" data.state">{{data.state}}</span>
              <span v-else>-</span>
            </template>
          </Column>
          <!-- <Column header="Pincode" :field="columns[5]">
            <template #body="{ data }">
              {{ data.pincode }}
            </template>
          </Column> -->
          <Column header="Pincode" :field="columns[11]" style="text-align:right;">
            <template #body="{ data }">
              <span v-if=" data.pincode">{{data.pincode}}</span>
              <span v-else>-</span>
            </template>
          </Column>
          <!-- <Column header="Mobile" :field="columns[9]" style="text-align:right;">
            <template #body="{ data }">
              {{ data.mobile_number }}
            </template>
          </Column>
          <Column header="Email" :field="columns[10]">
            <template #body="{ data }">
              {{ data.email_id }}
            </template>
          </Column> -->

          <Column header="Action" headerStyle="min-width:15rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-eye"
                v-tooltip.bottom="'View'"
                class="p-button-rounded p-button-success mr-2"
                @click="view_profile_Product(slotProps.data)"
              />
              <Button
                icon="pi pi-pencil"
                title="Edit Client"
                v-tooltip.bottom="'Edit'"
                class="p-button-rounded p-button-help mr-2"
                @click="editProduct(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                v-tooltip.bottom="'Delete'"
                class="p-button-rounded p-button-warning mr-2"
                @click="confirmDeleteProduct(slotProps.data)"
              />
              
             
              <!-- <Button label="Map Group" icon="pi pi-arrow-right" class="p-button-rounded p-button-primary mt-2"
                @click="viewProduct(slotProps.data)" /> -->
              
            </template>
          </Column>
        </DataTable>

        <Sidebar
          v-model:visible="productDialog"
          :baseZIndex="1000"
          position="full"
        >
          <div class="col-12">
            <div class="card">
              <h5>Departmet Form</h5>
              <hr />
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-2">
                  <label for="status">Status</label>
                  <Dropdown
                    id="status"
                    v-model="selectstatus"
                    :options="dropdownItems"
                    :value="dropdownItems.value"
                    optionLabel="name"
                    placeholder="Select One"
                    :class="{ 'p-invalid': submitted && !selectstatus }"
                    required="true"
                  ></Dropdown>
                </div>
                

                <div class="field col-12 md:col-4">
                  <label for="name">Department Name</label>
                  <InputText
                    id="name"
                    type="text"
                    v-model.trim="product.name"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.name }"
                  />
                </div>

             
                
                <div class="field col-12 md:col-3">
                  <!-- <label for="pincode">Start Point - {{start?start.substring(0,60):''}}</label> -->
                  <label for="pincode">Start Location</label>
                
                  <Dropdown 
                  @change="get_location"
                  v-model="pick_search" 
                  :options="pick_dropdown" 
                  optionLabel="name" 
                  filter 
                  @filter="dropdown_list"
                  placeholder="Select Start Point" 
                  >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{slotProps.value.name}}</div>
            </div>
              <span v-else>
                {{slotProps.placeholder}}
              </span>
          </template>
          <template #option="slotProps">
            <span>{{slotProps.option.name}}</span>
          </template>
          
        </Dropdown>
                  
                </div>


                <div class="field col-12 md:col-3">
                  <label for="pincode">End Location</label>
                  <Dropdown 
                  @change="end_get_location"
                  v-model="end_pick_search" 
                  :options="end_pick_dropdown" 
                  optionLabel="name" 
                  filter 
                  @filter="end_dropdown_list"
                  placeholder="Select End Point" 
                  >
                  <template #value="slotProps">
                    <div class="country-item country-item-value" v-if="slotProps.value">
                      <div>{{slotProps.value.name}}</div>
                    </div>
                  <span v-else>
                  {{slotProps.placeholder}}
                  </span>
                  </template>
                  <template #option="slotProps">
                  <span>{{slotProps.option.name}}</span>
                  </template>
                  </Dropdown>
                </div>
                <div class="field col-12 md:col-2">
                  <label for="state">State</label>
                  <InputText
                    id="state"
                    type="text"
                    v-model.trim="product.state"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.state }"
                  />
                </div>

               
                <div class="field col-12 md:col-2">
                  <label for="city">City/Village</label>
                  <InputText
                    id="city"
                    type="text"
                    v-model.trim="product.city"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.city }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="pincode">Pincode</label>
                  <InputText
                    id="pincode"
                    type="number"
                    v-model.trim="product.pincode"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.pincode }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="contact_person">Contact Person</label>
                  <InputText
                    id="contact_person"
                    type="text"
                    v-model.trim="product.contact_person"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.contact_person }"
                  />
                </div>
                
                <div class="field col-12 md:col-2">
                  <label for="contact_person_number">Contact Person Number</label>
                  <InputText
                    id="contact_person_number"
                    type="text"
                    v-model.trim="product.contact_person_number"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.contact_person_number }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="email_id">Email</label>
                  <InputText
                    id="email_id"
                    type="text"
                    v-model.trim="product.email_id"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.email_id }"
                  />
                </div>


                


              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card">
              <div class="p-fluid formgrid grid">
                <div class="field col-12">
                  <label for="description">Description</label>

                  <Textarea
                    id="description"
                    type="text"
                    v-model.trim="product.description"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.description }"
                  />
                </div>
              
            
              </div>
            </div>
          </div>

          <div class="col-12 md:col-6">
            <Button
              v-if="product._id"
              label="UPDATE"
              icon="pi pi-check"
              class="p-button-success mr-1 mb-1"
              @click="adduser"
            />
            <Button
              v-else
              label="ADD"
              icon="pi pi-check"
              class="p-button-success mr-1 mb-1"
              @click="adduser"
            />
            <Button
              label="Cancel"
              icon="pi pi-times"
              class="p-button-danger mr-1 mb-1"
              @click="productDialog = false"
            />
          </div>
        </Sidebar>

        <Dialog
          v-model:visible="productDialog_old"
          :style="{ width: '450px', width: '450px' }"
          header="Year Details"
          :modal="true"
          class="p-fluid"
        >
          <div class="grid">
            <div class="col-12 md:col-12">
              <div class="field">
                <!-- <img
            :src="'images/product/shoes.jpg'"
            :alt="product.image"
            width="150"
            class="mt-0 mx-auto mb-5 block shadow-2"
          /> -->
              </div>
              <div class="field">
                <label for="enrollmenT_YEAR"
                  >Enrollement Year <i style="color: green">(eg.2022)</i></label
                >
                <InputText
                  id="enrollmenT_YEAR"
                  v-model.trim="product.enrollmenT_YEAR"
                  required="true"
                  autofocus
                  :class="{
                    'p-invalid': submitted && !product.enrollmenT_YEAR,
                  }"
                />
              </div>
              <div class="field">
                <label for="exaM_YEAR"
                  >Exam Year <i style="color: green">(eg.2022-23)</i></label
                >
                <InputText
                  id="exaM_YEAR"
                  v-model.trim="product.exaM_YEAR"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !product.exaM_YEAR }"
                />
                <!-- <small class="p-invalid" v-if="submitted && !product.name"
                  >Name is required.</small
                > -->
              </div>
              <!-- <div class="field">
                <label for="description">Description</label>
                <Textarea
                  id="description"
                  v-model.trim="product.description"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !product.description }"
                />
              </div>
              <div class="field">
                <label for="link">Video Link</label>
                <InputText
                  id="link"
                  v-model.trim="product.videolink"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !product.link }"
                />
              </div>
              
              <div class="field">
                <label for="status">Type</label>

                <Dropdown
                  id="state"
                  v-model="selectstatus"
                  :options="dropdownItems"
                  :value="dropdownItems.value"
                  optionLabel="name"
                  placeholder="Select One"
                  :class="{ 'p-invalid': submitted && !selectstatus }"
                  required="true"
                ></Dropdown>
              </div>

              <div class="field">
                <label for="status">Status</label>

                <Dropdown
                  id="state"
                  v-model="selectstatus2"
                  :options="dropdownItems2"
                  :value="dropdownItems2.value"
                  optionLabel="name"
                  placeholder="Select One"
                  :class="{ 'p-invalid': submitted && !selectstatus2 }"
                  required="true"
                ></Dropdown>
              </div> -->
            </div>
            <div class="col-12 md:col-6">
              <!-- <Button
                v-if="product.mjyeaR_ID"
                label="UPDATE"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="Addyear"
               
              />
              <Button
                v-else
                label="ADD"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="Addyear"
              /> -->
            </div>
            <div class="col-12 md:col-6">
              <Button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-danger mr-1 mb-1"
                @click="productDialog = false"
              />
            </div>
          </div>
        </Dialog>

        <Dialog header="Map Group" v-model:visible="viewproductDialog" :breakpoints="{'960px': '75vw'}" :style="{width: '40vw'}" :modal="true">
          <div class="grid">
        <div class="col-12 md:col-12">
      <MultiSelect display="chip" v-model="selectedgroup" :options="groups" optionLabel="name" placeholder="Select Group"
    :maxSelectedLabels="3" class="w-full md:w-20rem" />&nbsp;
    <Button label="add"  class="p-button-success p-button-sm" @click="add_group()"/>
    </div>
    </div>
  
      </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete <b>{{ product.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteuser"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete the selected products?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
import JsonExcel from "vue-json-excel";
import BreadcrumbService from "../service/BreadcrumbService";

//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      user_key:"",
      client_id_dropdown: [],
      client_id_search:'',


      department_name:'',
      start:'',
      end:'',
      
      searchme:'',
 
      page_no:1,
      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,
      file_attachment: "",

      pick_dropdown: [],
      end_pick_dropdown: [],
      pick_search:null,
      end_pick_search:null,
      place_details:'',
      end_place_details:'',
      groups:[],
      selectedgroup:null,



  

      dropdownItems: [
        { name: "Active", value: "Active" },
        { name: "InActive", value: "InActive" },
        { name: "Deleted", value: "Deleted" },
      ],
      selectstatus: { name: "", value: "" },


      products: null,
      productDialog: false,
      viewproductDialog:false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      
      
      // selectstatus2: { name: "Motivational", value: "Motivational" },
      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus",
        "Course",
      ],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],

      BreadcrumbService : null,
breadcrumbHome: {},
breadcrumbItems: [],


    };
  },
  productService: null,
  
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    this.group_list();
    this.get_client_list();
    this.user_key=localStorage.getItem("user_type");

    this.BreadcrumbService = new BreadcrumbService();
	let Breadcrum_data = this.BreadcrumbService.client_department()
	this.breadcrumbHome=Breadcrum_data.home;
	this.breadcrumbItems=Breadcrum_data.items;
    
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();


    
    //alert(this.id);
  },
  watch: {
   async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
    get_location()
    { 
      this.place_details='';
      var data = { PlaceId:this.pick_search.value};
      var promise = apis.geocodelocationdetails(data);
      promise.then((response) => {
        if (response.data.result) 
        {
          this.place_details=response.data.result[0];
          this.product.pincode=response.data.result[0].PostalCode?response.data.result[0].PostalCode:'';
          this.product.state=response.data.result[0].Municipality?response.data.result[0].Region:'';
          this.product.city=response.data.result[0].Municipality?response.data.result[0].Municipality:'';
        }
        
        
      });

    },
     dropdown_list(search)
    { 
     var data = { search:search.value};
      var promise = apis.geocodelats(data);
      promise.then((response) => {
        this.pick_dropdown=[];
        var temp=response.data.result;
        for (let a = 0; a < temp.length; a++) {
          this.pick_dropdown.push( { name: temp[a].Text, value: temp[a].PlaceId });
          
        }
      });
    },
    end_get_location()
    { 
      this.end_place_details='';
      var data = { PlaceId:this.end_pick_search.value};
      var promise = apis.geocodelocationdetails(data);
      promise.then((response) => {
        if (response.data.result) 
        {
          this.end_place_details=response.data.result[0];
        }
        
        
      });

    },
     end_dropdown_list(search)
    { 
     var data = { search:search.value};
      var promise = apis.geocodelats(data);
      promise.then((response) => {
        this.end_pick_dropdown=[];
        var temp=response.data.result;
        for (let a = 0; a < temp.length; a++) {
          this.end_pick_dropdown.push( { name: temp[a].Text, value: temp[a].PlaceId });
          
        }
      });
    },
   
           isNumber(evt) {  
      const charCode = evt.which ? evt.which : evt.keyCode;  
      if (  
        charCode > 31 &&  
        (charCode < 48 || charCode > 57) &&  
        charCode !== 46  
      ) {  
        evt.preventDefault();  
      }  
    }, 
    add_group()
    { 
      if (this.selectedgroup && this.selectedgroup.length>0) {
        var temp=[];
        for (let z = 0; z < this.selectedgroup.length; z++) {
          temp.push(this.selectedgroup[z].value);
          
        }
      }
      else{
        this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please select at least one group",
            life: 3000,
          });
          return false;
      }
      var data = {
        "department_id":this.product._id,
        "client_id": localStorage.getItem("client_id"),
        "group_ids": temp
      }
      console.log(data);
      this.isLoadingModel = true;
        var promise = apis.clientDepartmentMappGroup(data);
        promise
          .then((responseapi) => {
            this.$swal(responseapi.data.message);
            this.isLoadingModel = false;
            this.viewproductDialog = false;
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.viewproductDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
    } ,

    async search(count)
    { 
      count==2?this.department_name='':'';
      count==2?this.client_id_search='':'';
      await this.get_list();
      await this.get_count();
    },
    async view_profile_Product(product)
      {
        console.log(JSON.stringify(product));
        await localStorage.setItem('savedepartmentdata',JSON.stringify(product));
        this.$router.push({name:"DepartmentProfile"});
      },
      
    async viewProduct(product)
    {
      this.product = { ...product };
      this.viewproductDialog = true;
      this.selectedgroup=null;
      if (this.product.group_ids) 
      { 
       
        var temp_data = [];
        
                    for (let x = 0; x < this.product.group_ids.length; x++) 
                    { 
                      for (let z = 0; z < this.groups.length; z++) 
                      {
                          if (this.groups[z].value==this.product.group_ids[x]) 
                          {
                            temp_data.push({ name: this.groups[z].name, value: this.groups[z].value });
                          }
                        
                      }
                    }
        this.selectedgroup=temp_data;
      } 
      
      
    },
    group_list()
    {
      
            this.loading = true;
            var data = { client_id: localStorage.getItem("client_id") };
            var promise = apis.groupList(data);
            promise.then((response) => {
                this.loading = false;
                console.log(response);
                this.list = response.data.data;
                if (response.data.data) {
                    var temp_data = [];
                    for (let x = 0; x < response.data.data.length; x++) {
                        temp_data.push({ name: response.data.data[x].name, value: response.data.data[x]._id });

                    }
                    this.groups = temp_data;
                }

            });
       
    },
    //a simple date formatting function
    dateFormat(inputDate, info) {},
    handleFileUpload() {
      this.file_attachment = this.$refs.file_attachment.files[0];

      //alert(this.$refs.file_attachment.files[0].name);
    },
    get_time(date)
    {
      const now = new Date(date);
      const currentTimeInMillis = now.getTime();
      return currentTimeInMillis;
    },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
    //  await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      
      var data = {
        client_id: this.client_id_search.value?this.client_id_search.value:localStorage.getItem("client_id"),
        count: true,
        department_name:this.department_name
        
      };
      this.loading = true;
      var promise = apis.clientdepartmentlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.totalRecords = response.data.data;
        //alert(this.totalRecords);
      });

      console.log(data);
    },
    get_list: function () {
      
      var data = {
        limit: this.limit,
        page_no: this.page_no,
        count: false,
        client_id:this.client_id_search.value?this.client_id_search.value:localStorage.getItem("client_id"),
        department_name:this.department_name

      };
      this.loading = true;
      var promise = apis.clientdepartmentlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.products = response.data.data;
        console.log(this.products[0]);
        if (this.columns.length == 0) {
          if (this.products.length > 0) {
            this.columns = Object.keys(this.products[0]);
          }
        }
      });

      console.log(data);
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
      
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    time_converter(data)
    {
      const date = new Date(data); // create a Date object with the given UTC date and time 
      const options = { hour: 'numeric', minute: '2-digit', hour12: true }; // define the options for the time format
      const time = date.toLocaleString('en-US', options); // convert the date and time to a string in the specified format and time zone
      return time;
    },
    async adduser() {
      this.submitted = true;
      if (this.selectstatus==null || this.selectstatus.value=='' || this.selectstatus.value==undefined ||  this.selectstatus.value==[]) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Select Status",
          life: 3000,
        });
        return false;
      }
      
      if (!this.product.name) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Department Name",
          life: 3000,
        });
        return false;
      }
      
     
      
      
      
      if (this.place_details==undefined || this.place_details==null || this.place_details==[]) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Start Location",
          life: 3000,
        });
        return false;
      }
      if (this.end_place_details==undefined || this.end_place_details==null || this.end_place_details==[]) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter End Location",
          life: 3000,
        });
        return false;
      }
      if (!this.product.pincode) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Pincode",
          life: 3000,
        });
        return false;
      }
      if (!this.product.contact_person) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter contact_person",
          life: 3000,
        });
        return false;
      }
      if (!this.product.contact_person_number) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter contact_person_number",
          life: 3000,
        });
        return false;
      }
      if (!this.product.email_id) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter email_id",
          life: 3000,
        });
        return false;
      }
      if (!this.product.description) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter description",
          life: 3000,
        });
        return false;
      }
     
      
      

      //edit
      if (this.product._id) {
        this.product.pickup_point=this.place_details;
        this.product.end_point=this.end_place_details;

          var data = {
        "_id":this.product._id,
        "user_id": localStorage.getItem("id"),
        "client_id": this.client_id_search.value?this.client_id_search.value:localStorage.getItem("client_id"),
        "name": this.product.name,
        "description": this.product.description,
        "status": this.selectstatus.value,
        "city": this.product.city,
        "pincode": this.product.pincode,
        "state": this.product.state,
        "location": this.place_details,
        "location_end": this.end_place_details,
        "contact_person": this.product.contact_person,
        "contact_person_number": this.product.contact_person_number,
        "email_id": this.product.email_id,
        "created_at": "",
        "updated_at": "",
        "created_by": localStorage.getItem("id"),
        "updated_by": "",
      
        };
       
        this.isLoadingModel = true;
        var promise = apis.clientDepartmentUpdate(data);
        promise
          .then((responseapi) => {
            this.$swal(responseapi.data.message);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }
      //add-----------------------------------------------------------------------------------------------------------------
      else {
        var data1 = {
        "user_id": localStorage.getItem("id"),
        "client_id": this.client_id_search.value?this.client_id_search.value:localStorage.getItem("client_id"),
        "name": this.product.name,
        "description": this.product.description,
        "status": this.selectstatus.value,
        "city": this.product.city,
        "pincode": this.product.pincode,
        "state": this.product.state,
        "location": this.place_details,
        "location_end": this.end_place_details,
        "contact_person": this.product.contact_person,
        "contact_person_number": this.product.contact_person_number,
        "email_id": this.product.email_id,
        "created_at": "",
        "updated_at": "",
        "created_by": localStorage.getItem("id"),
        "updated_by": "",
        
        };
      
        this.isLoadingModel = true;
        var promises = apis.clientDepartmentAdd(data1);
        promises
          .then((response) => {
            this.isLoadingModel = false;
            this.productDialog = false;
            this.$swal(response.data.message);
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }

      this.selectstatus = { name: "", value: "" };
      
      this.pick_dropdown=[];
      this.end_pick_dropdown=[];
      this.place_details='';
      this.end_place_details='';
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },
    get_client_list()
    { 
      var data = { count: false};
      var promise = apis.getclientist(data);
        promise.then((response) => {
          if(response.data.data){
          response.data.data
            .filter(item => item.client_name)
            .map(item => this.client_id_dropdown.push({ name: item.client_name, value: item._id }));
          }
        });
    },
    format_time(data)
    {
      if(data) 
      {
        const date = new Date(data);
        return date;
      }
      else
      {
        return '';
      }

    },
    format_time2(data)
    {
      const timeString = data; // the time string to convert
      const defaultDateString = new Date().toDateString(); // use the current date as the default date
      const dateTimeString = `${defaultDateString} ${timeString}`; // combine the default date and the time string
      const date = new Date(dateTimeString); // create a Date object from the complete date and time string
      return date;

    },
    
    editProduct(product) {
      this.file_attachment = "";
      this.product = { ...product };
      this.productDialog = true;
      this.selectstatus.value = this.product.status;
      this.selectstatus.name = this.product.status;

      this.end_pick_search=null;
      this.pick_search=null;
      if(this.product.location_end && this.product.location)
      {
      this.end_pick_search={ "name": this.product.location_end?this.product.location_end.Label:'', "value":this.product.location_end?this.product.location_end:''};
      this.pick_search={ "name": this.product.location?this.product.location.Label:'', "value":this.product.location?this.product.location:''};
      }
      
      this.place_details=this.product.location?this.product.location:'';
      this.end_place_details =this.product.location_end?this.product.location_end:'';

      
     

      
      
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deleteuser() {
      if (this.product._id) {
        var data = {
          user_id: localStorage.getItem("id"),
          id: this.product._id
        };
        this.isLoadingModel = true;
        var promise = apis.client_DepartmentDelete(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.data.status == true) {
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data.message);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Video");
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    
  async  exportCSV() {
      this.limit=this.totalRecords;
      this.get_list();
     await this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {downloadExcel: JsonExcel},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
